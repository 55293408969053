import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Slideshow.css";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const Slideshow = (props) => {
    const albumData = props.albumData;
    const [photosCount, setPhotosCount] = useState(0)
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.indexOf("sights") >= 0) {
            return setPhotosCount(3);
        }
        else {
            return setPhotosCount(albumData.photosCount);
        }
    }, [location, albumData])
    
    const getPhotoContainerStyle = () => {
        if (location.pathname.indexOf("sights") >= 0) {
            return { height: "460px" }
        }
        else {
            return {}
        }
    }

    const getPhotosContent = () => {
        let content = [];
        for (let i = 1; i < (photosCount+1); i++) {
            content.push(
                <div className="SlideshowPhotoContainer" key={i} style={getPhotoContainerStyle()}>
                    <div className="SlideshowPhoto" style={{ backgroundImage: `url(/photo_albums/id_${albumData.id}/${i}.JPG)` }}>
                        {/* <span>Slide {index + 1}</span> */}
                    </div>
                </div>
            )
        }
        
        return content;
    }
    return (      
        <div className="SliderPage">
            <div className={"Slider"}>
                <Slide easing="ease" autoplay={false}>
                    {getPhotosContent()}
                </Slide>
            </div>
        </div>
    );
}

export default Slideshow;