import { Link } from "react-router-dom";
import { Accordion } from "../Accordion/Accordion"
import "./SideMenu.css";

import pdf1 from "../../data/bibliography/1.pdf"
import pdf2 from "../../data/bibliography/2.pdf"
import pdf3 from "../../data/bibliography/3.pdf"
import pdf4 from "../../data/bibliography/4.pdf"
import pdf5 from "../../data/bibliography/5.pdf"
import pdf6 from "../../data/bibliography/6.pdf"
import pdf7 from "../../data/bibliography/7.pdf"
import pdf8 from "../../data/bibliography/8.pdf"
import pdf9 from "../../data/bibliography/9.pdf"
import pdf10 from "../../data/bibliography/10.pdf"
import pdf11 from "../../data/bibliography/11.pdf"
import pdf12 from "../../data/bibliography/12.pdf"
import pdf13 from "../../data/bibliography/13.pdf"
import pdf14 from "../../data/bibliography/14.pdf"
import pdf15 from "../../data/bibliography/15.pdf"
import pdf16 from "../../data/bibliography/16.pdf"
import { useState } from "react";

const culturalHeritageData = {
    id: 1,
    title: "Културно наследство",
    children: [
        {
            id: 2,
            title: "Общ поглед",
            link: "/",
            style: "second"
        },
        {
            id: 3,
            title: "По вид",
            children: [
                {
                    id: 4,
                    title: "Археологическо",
                    link: "/themes/by_type/archeological",
                    style: "third"
                },
                {
                    id: 5,
                    title: "Архитектурно",
                    link: "/themes/by_type/architectural",
                    style: "third"
                },
                {
                    id: 6,
                    title: "Историческо",
                    link: "/themes/by_type/historical/0", // doesn't have subcategories, so treat as one category with id: 0
                    style: "third"
                },
                {
                    id: 7,
                    title: "Нематериално",
                    link: "/themes/by_type/abstract/0", // doesn't have subcategories, so treat as one category with id: 0
                    style: "third"
                },
                {
                    id: 8,
                    title: "Фествали и културни събития",
                    link: "/themes/by_type/festival/0", // doesn't have subcategories, so treat as one category with id: 0
                    style: "third"
                }
            ],
            style: "second"
        },
        {
            id: 9,
            title: "По общини",
            children: [
                {
                    id: 10,
                    title: "Община Братя Даскалови",
                    link: "/themes/by_region/bratya_daskalovi/0",
                    style: "third"
                },
                {
                    id: 11,
                    title: "Община Гурково",
                    link: "/themes/by_region/gurkovo/0",
                    style: "third"
                },
                {
                    id: 12,
                    title: "Община Гълъбово",
                    link: "/themes/by_region/galabovo/0",
                    style: "third"
                },
                {
                    id: 13,
                    title: "Община Казанлък",
                    link: "/themes/by_region/kazanlak/0",
                    style: "third"
                },
                {
                    id: 14,
                    title: "Община Мъглиж",
                    link: "/themes/by_region/maglizh/0",
                    style: "third"
                },
                {
                    id: 15,
                    title: "Община Николаево",
                    link: "/themes/by_region/nikolaevo/0",
                    style: "third"
                },
                {
                    id: 16,
                    title: "Община Павел баня",
                    link: "/themes/by_region/pavel_banya/0",
                    style: "third"
                },
                {
                    id: 17,
                    title: "Община Раднево",
                    link: "/themes/by_region/radnevo/0",
                    style: "third"
                },
                {
                    id: 18,
                    title: "Община Стара Загора",
                    link: "/themes/by_region/stara_zagora/0",
                    style: "third"
                },
                {
                    id: 19,
                    title: "Община Чирпан",
                    link: "/themes/by_region/chirpan/0",
                    style: "third"
                }
            ],
            style: "second"
        },
    ],
    style: "first"
}

const photoAlbumsData = {
    id: 20,
    title: "Фотогалерия",
    children: [
        {
            id: 21,
            title: "Археология",
            children: [
                {
                    id: 22,
                    title: "Антични вили",
                    link: "/albums/archeology/ancient_cottages",
                    style: "third"
                },
                {
                    id: 23,
                    title: "Антични градове",
                    link: "/albums/archeology/ancient_cities",
                    style: "third"
                },
                {
                    id: 24,
                    title: "Гробници",
                    link: "/albums/archeology/tombs",
                    style: "third"
                },
                {
                    id: 25,
                    title: "Крепости",
                    link: "/albums/archeology/forts",
                    style: "third"
                },
                {
                    id: 26,
                    title: "Мозайки",
                    link: "/albums/archeology/mosaic",
                    style: "third"
                },
                {
                    id: 27,
                    title: "Светилища",
                    link: "/albums/archeology/temples",
                    style: "third"
                }
            ],
            style: "second"
        },
        {
            id: 28,
            title: "Архитектура",
            children: [
                {
                    id: 29,
                    title: "Градска архитектура",
                    link: "/albums/architecture/cities",
                    style: "third"
                },
                {
                    id: 30,
                    title: "Домове на творци и къщи музеи",
                    link: "/albums/architecture/houses",
                    style: "third"
                },
                {
                    id: 31,
                    title: "Религиозни храмове",
                    link: "/albums/architecture/religious",
                    style: "third"
                },
                {
                    id: 32,
                    title: "Дом-паметник на връх Бузлуджа",
                    link: "/albums/architecture/buzludzha",
                    style: "third"
                }
            ],
            style: "second"
        },
        {
            id: 33,
            title: "История",
            link: "/albums/history/single",
            style: "second"
        },
        {
            id: 34,
            title: "Фестивали и културни събития",
            link: "/albums/festival/single",
            style: "second"
        }
    ],
    style: "first"
}

const bibliographyData = {
    id: 35,
    title: "Библиографии",
    children: [
        {
            id: 36,
            title: "Археологическо културно наследство",
            children: [
                {
                    id: 37,
                    title: "За библиографията",
                    link: pdf1,
                    style: "third",
                    isPdf: true
                },
                {
                    id: 38,
                    title: "Общи библиографски описания",
                    link: pdf2,
                    style: "third",
                    isPdf: true
                },
                {
                    id: 39,
                    title: "Антични вили",
                    link: pdf3,
                    style: "third",
                    isPdf: true
                },
                {
                    id: 40,
                    title: "Антични градове",
                    link: pdf4,
                    style: "third",
                    isPdf: true
                },
                {
                    id: 41,
                    title: "Антични терми",
                    link: pdf5,
                    style: "third",
                    isPdf: true
                },
                {
                    id: 42,
                    title: "Гробници",
                    link: pdf6,
                    style: "third",
                    isPdf: true
                },
                {
                    id: 43,
                    title: "Крепости",
                    link: pdf7,
                    style: "third",
                    isPdf: true
                },
                {
                    id: 44,
                    title: "Медни рудници",
                    link: pdf8,
                    style: "third",
                    isPdf: true
                },
                {
                    id: 45,
                    title: "Могили",
                    link: pdf9,
                    style: "third",
                    isPdf: true
                },
                {
                    id: 46,
                    title: "Мозайки",
                    link: pdf10,
                    style: "third",
                    isPdf: true
                },
                {
                    id: 47,
                    title: "Некрополи",
                    link: pdf11,
                    style: "third",
                    isPdf: true
                },
                {
                    id: 48,
                    title: "Светилища",
                    link: pdf12,
                    style: "third",
                    isPdf: true
                },
                {
                    id: 49,
                    title: "Селища",
                    link: pdf13,
                    style: "third",
                    isPdf: true
                },
                {
                    id: 50,
                    title: "Други обекти",
                    link: pdf14,
                    style: "third",
                    isPdf: true
                }
            ],
            style: "second"
        },
        {
            id: 51,
            title: "Архитектурно културно наследство",
            link: pdf15,
            style: "second",
            isPdf: true
        },
        {
            id: 52,
            title: "Нематериално културно наследство",
            link: pdf16,
            style: "second",
            isPdf: true
        }
    ],
    style: "first"
}

const SideMenu = (props) => {
    const [currentNavId, setCurrentNavId] = useState(1)

    return (
       <ul className="SideMenuList">
            <li className="SideMenuItems">
                <Accordion data={culturalHeritageData} current={currentNavId} setCurrent={setCurrentNavId} collapseAllSubmenus={props.collapseAllSubmenus}/>
                <Accordion data={photoAlbumsData} current={currentNavId} setCurrent={setCurrentNavId} collapseAllSubmenus={props.collapseAllSubmenus}/>
                <Accordion data={bibliographyData} current={currentNavId} setCurrent={setCurrentNavId} collapseAllSubmenus={props.collapseAllSubmenus}/>
                <li className="Navbar-item">
                    <Link to={"/links"}><p className="Navbar-item-text first">Връзки</p></Link>
                </li>
            </li>
       </ul> 
    )
}

export default SideMenu;