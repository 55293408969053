import "./Reports.css"

const Reports = () => {
    const reportsCount = 5;
    const getReportsContent = () => {
        let content = [];
        for (let i = 0; i < reportsCount; i++) {
            content.push(
                <div className="ReportPhoto" key={i} style={{ backgroundImage: `url(/reports/${i+1}.jpg)` }}>
                </div>
            )
        }
        
        return content;
    }
    return (
        <div className="ReportsPage">
            <div className="ReportsList">
                {getReportsContent()}
            </div>
        </div>
    )
}

export default Reports;