import React from 'react';
import './News.css';
import news from './newsData';

const News = () => {
	return (
		<div className="News">
			<h1>Новини</h1>
			<ul className="NewsList">
				{news.map(newData => (
					<li className="NewsListItem">
						<h2>{newData.title}</h2>
						<p>{newData.summary}</p>
					</li>
				))}
			</ul>
		</div>
	)
}

export default News;