import React, { useEffect, useState } from 'react'
import allLinks from "../../data/links/links.js"
import './Links.css';

const Links = () => {
    return (
        <div className="LinksPage">
            {allLinks.map(links => (
                <div className="LinkCategory">
                    <h2>{links[0].category}</h2>
                    <ul>{links.map(link => (
                        <li>
                            <a href={link.url} target="_blank">{link.title}</a>
                        </li>
                    ))}
                    </ul>  
                </div>
            ))}
        </div>
    )
}

export default Links;