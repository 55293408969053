import "./Papers.css"
import papers from "../../data/results/papersData.js"

const Papers = () => {
    return (
        <div className="PapersPage">
            <div className="PapersList">
                {papers.map(paper => (
                    <div className="PapersListItem">
                        {paper.magazine ? <p>Публикация в научно списание</p> : <p>Публикация в сборник доклади от научна конференция</p>}
                        <h3 className="PaperTitle">{paper.name}</h3>
                        <div><span>Автор: </span>{paper.authors[0]}</div>
                        <div className="PaperData">
                            <div><span>Статус: </span>{paper.status}</div>
                            <div><span>Година: </span>{paper.year}</div>
                            {paper.magazine && <div><span>Списание: </span>{paper.magazine}</div>}
                            {paper.tom && <div><span>Том: </span>{paper.tom}</div>}
                            {paper.collection && <div><span>Сборник доклади: </span>{paper.collection}</div>}
                            {paper.editor && <div><span>Редактор: </span>{paper.editor}</div>}
                            {paper.publisher && <div><span>Издава: </span>{paper.publisher}</div>}
                            {paper.count && <div><span>Брой: </span>{paper.count}</div>}
                            <div><span>Страници: </span>{paper.pages}</div>
                        </div>
                        <div><span>URL/DOI: </span><a href={paper.url} target="_blank">{paper.url}</a></div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Papers;