import "./Press.css"

const Press = () => {
   
    return (
        <div className="PressPage">
            <h2>Други публикации</h2>
            <div className="PressItem">
                <div className="PressItemLeft">
                    <img src={require("../../assets/img/press/catalogue.jpg")}></img>
                </div>
                <div className="PressItemRight">
                    <p><span>Заглавие: </span>101 събития и обекти на културното наследство на област Стара Загора</p>
                    <p><span>Автор: </span>Стела Стефанова</p>
                    <p><span>Фотография: </span>Стела Стефанова</p>
                    <p><span>Предпечат и дизайн: </span>Огнян Димитров</p>
                    <p><span>Тип на публикацията: </span>Каталог</p>
                    <p><span>Година на издаване: </span>2024</p>
                    <p><span>Страници: </span>20</p>
                    <p><span>Издател: </span>ИК ЗИП</p>
                </div>
            </div>
            <div className="PressItem">
                <div className="PressItemLeft">
                    <img src={require("../../assets/img/press/calendar.jpg")}></img>
                </div>
                <div className="PressItemRight">
                    <p><span>Заглавие: </span>Интерактивен календар 2025</p>
                    <p><span>Подзаглавие: </span>Интегрирано дигитално представяне на културното наследство на област Стара Загора</p>
                    <p><span>Снимки и текст: </span>Стела Стефанова</p>
                    <p><span>Графичен дизайн: </span>Огнян Димитров</p>
                    <p><span>Видеомонтаж: </span>Юри Кичуков</p>
                    <p><span>Тип на публикацията: </span>Календар - Фотоалбум</p>
                    <p><span>Година на издаване: </span>2024</p>
                    <p><span>Страници: </span>14</p>
                    <p><span>Издател: </span>ИК ЗИП</p>
                </div>
            </div>
        </div>
    )
}

export default Press;