const museumLinks = [
    {
        title: "Национален парк-музей „Шипка - Бузлуджа“",
        category: "Музеи",
        url: "https://www.shipkamuseum.org/"
    },
    {
        title: "Регионален исторически музей - Стара Загора",
        category: "Музеи",
        url: "https://www.rimstz.eu/"
    },
    {
        title: "Исторически музей „Искра“ - Казанлък",
        category: "Музеи",
        url: "https://www.muzei-kazanlak.org/"
    },
    {
        title: "Исторически музей - Чирпан",
        category: "Музеи",
        url: "https://www.facebook.com/p/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8-%D0%BC%D1%83%D0%B7%D0%B5%D0%B9-%D0%A7%D0%B8%D1%80%D0%BF%D0%B0%D0%BD-100063775370656/"
    },
    {
        title: "Археологически музей „Марица-Изток“ - Раднево",
        category: "Музеи",
        url: "https://www.facebook.com/p/%D0%90%D1%80%D1%85%D0%B5%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8-%D0%BC%D1%83%D0%B7%D0%B5%D0%B9-%D0%9C%D0%B0%D1%80%D0%B8%D1%86%D0%B0-%D0%98%D0%B7%D1%82%D0%BE%D0%BA-100048654888444/"
    },
    {
        title: "Музей „Неолитни жилища“, Стара Загора",
        category: "Музеи",
        url: "https://www.rimstz.eu/%D0%BC%D1%83%D0%B7%D0%B5%D0%B9-%C2%AB%D0%BD%D0%B5%D0%BE%D0%BB%D0%B8%D1%82%D0%BD%D0%B8-%D0%B6%D0%B8%D0%BB%D0%B8%D1%89%D0%B0%C2%BB"
    },
    {
        title: "Късноантична мозайка от частен римски дом, Стара Загора",
        category: "Музеи",
        url: "https://www.rimstz.eu/k%D1%8A%D1%81%D0%BD%D0%BE%D0%B0%D0%BD%D1%82%D0%B8%D1%87%D0%BD%D0%B0-%D0%BC%D0%BE%D0%B7%D0%B0%D0%B9%D0%BA%D0%B0"
    },
    {
        title: "Архитектурен комплекс „Музей на религиите“, Стара Загора",
        category: "Музеи",
        url: "https://www.rimstz.eu/%D0%B0%D1%80%D1%85%D0%B8%D1%82%D0%B5%D0%BA%D1%82%D1%83%D1%80%D0%B5%D0%BD-%D0%BA%D0%BE%D0%BC%D0%BF%D0%BB%D0%B5%D0%BA%D1%81-%C2%AB%D0%BC%D1%83%D0%B7%D0%B5%D0%B9-%D0%BD%D0%B0-%D1%80%D0%B5%D0%BB%D0%B8%D0%B3%D0%B8%D0%B8%D1%82%D0%B5%C2%BB"
    },
    {
        title: "Музей на розата, Казанлък",
        category: "Музеи",
        url: "https://www.muzei-kazanlak.org/index.php?option=com_content&view=article&id=62%3A2013-12-05-21-36-24&catid=50%3A2013-12-01-08-32-16&Itemid=83&lang=bg"
    },
    {
        title: "Музей на етеричните масла, Казанлък",
        category: "Музеи",
        url: "https://eomuseum.com/index.html"
    },
    {
        title: "Музей „Литературна Стара Загора“",
        category: "Музеи",
        url: "https://literature-museum.bg/en/"
    },
    {
        title: "Къща музей „Гео Милев“, Стара Загора",
        category: "Музеи",
        url: "https://geomilev.com/"
    },
    {
        title: "Къща музей „П. К. Яворов“, Чирпан",
        category: "Музеи",
        url: "https://www.javorovhouse.info/?cid=5"
    },
    {
        title: "Литературно-художествен музей „Чудомир“, Казанлък",
        category: "Музеи",
        url: "https://chudomir.eu/"
    },
    {
        title: "Дом „Петко Стайнов“, Казанлък",
        category: "Музеи",
        url: "http://www.staynov.net/"
    },
    {
        title: "Къща музей „Градски бит XIX в.“, Стара Загора",
        category: "Музеи",
        url: "https://www.rimstz.eu/%D0%BA%D1%8A%D1%89%D0%B0-%D0%BC%D1%83%D0%B7%D0%B5%D0%B9-%C2%AB%D0%B3%D1%80%D0%B0%D0%B4%D1%81%D0%BA%D0%B8-%D0%B1%D0%B8%D1%82-%D1%85%D1%96%D1%85-%D0%B2%D0%B5%D0%BA%C2%BB"
    },
    {
        title: "Хилендарски метох, Стара Загора",
        category: "Музеи",
        url: "https://www.rimstz.eu/%D1%85%D0%B8%D0%BB%D0%B5%D0%BD%D0%B4%D0%B0%D1%80%D1%81%D0%BA%D0%B8-%D0%BC%D0%B5%D1%82%D0%BE%D1%85"
    },
    {
        title: "Етнографски комплекс „Кулата“, Казанлък",
        category: "Музеи",
        url: "https://www.muzei-kazanlak.org/index.php?option=com_content&view=article&id=70&Itemid=84&lang=bg"
    },
    {
        title: "Чирпанлиева къща, Шипка",
        category: "Музеи",
        url: "https://www.facebook.com/Chirpanlievahouse/?locale=bg_BG"
    },
    {
        title: "Джанановата къща, с. Габарево",
        category: "Музеи",
        url: "https://www.museology.bg/bg/museums/i211/%D0%B4%D0%B6%D0%B0%D0%BD%D0%B0%D0%BD%D0%BE%D0%B2%D0%B0%D1%82%D0%B0-%D0%BA%D1%8A%D1%89%D0%B0-%E2%80%93-%D1%81.-%D0%B3%D0%B0%D0%B1%D0%B0%D1%80%D0%B5%D0%B2%D0%BE.html"
    },
    {
        title: "Етнографска къща - село Спасово",
        category: "Музеи",
        url: "https://www.facebook.com/media/set/?set=a.859684064495727&s=12&_rdr"
    },
    {
        title: "Музей на фотографията и съвременните визуални изкуства, Казанлък",
        category: "Музеи",
        url: "https://mpcva.org/"
    },
]

const galleryLinks = [
    {
        title: "Художествена галерия - Стара Загора",
        category: "Галерии",
        url: "https://artgallerystz.com/"
    },
    {
        title: "Художествена галерия - Казанлък",
        category: "Галерии",
        url: "https://www.artgallerykazanlak.com/"
    },
    {
        title: "Музей „Ахинора“, Казанлък",
        category: "Галерии",
        url: "https://www.artgallerykazanlak.com/locations/muzey-ahinora/"
    },
    {
        title: "Къща музей „Дечко Узунов“, Казанлък",
        category: "Галерии",
        url: "https://www.artgallerykazanlak.com/locations/kashta-muzey-dechko-uzunov/"
    },
    {
        title: "Къща музей „Ненко Балкански“, Казанлък",
        category: "Галерии",
        url: "https://www.artgallerykazanlak.com/locations/kashta-muzey-nenko-balkanski/"
    },
    {
        title: "Художествена галерия - Раднево",
        category: "Галерии",
        url: "https://www.facebook.com/p/%D0%A5%D1%83%D0%B4%D0%BE%D0%B6%D0%B5%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%B0-%D0%B3%D0%B0%D0%BB%D0%B5%D1%80%D0%B8%D1%8F-%D0%A0%D0%B0%D0%B4%D0%BD%D0%B5%D0%B2%D0%BE-100066561362277/"
    },
    {
        title: "Художествена галерия „Никола Манев“, Чирпан",
        category: "Галерии",
        url: "https://www.chirpan.bg/bg/zabelezhitelnosti/hudozhestvena-galeriya-nikola-manev/"
    },
    {
        title: "Изложбена зала „Георги Данчов - Зографина“, Чирпан",
        category: "Галерии",
        url: "https://www.facebook.com/p/%D0%98%D0%B7%D0%BB%D0%BE%D0%B6%D0%B1%D0%B5%D0%BD%D0%B0-%D0%B7%D0%B0%D0%BB%D0%B0-%D0%93%D0%B5%D0%BE%D1%80%D0%B3%D0%B8-%D0%94%D0%B0%D0%BD%D1%87%D0%BE%D0%B2-%D0%97%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%BD%D0%B0-100090017490323/?_rdr"
    },
]

const festivalLinks = [
    {
        title: "Национално надиграване „Тъпан бие, хоро се вие“, Казанлък",
        category: "Фестивали и културни събития",
        url: "https://nadigravanekz.com/"
    },
    {
        title: "Национален тракийски фолклорен събор „Богородична стъпка”, Старозагорски бани",
        category: "Фестивали и културни събития",
        url: "https://www.facebook.com/BogorodichnaStapka/"
    },
    {
        title: "Международен филмов фестивал „Златната липа”, Стара Загора",
        category: "Фестивали и културни събития",
        url: "https://thegoldenlinden.com/"
    },
    {
        title: "Фестивал на виното и културното наследство „Августиада“, Стара Загора",
        category: "Фестивали и културни събития",
        url: "https://www.avgustiada.com/bg"
    },
    {
        title: "Национално надиграване „Тъпан бие, хоро се вие“, Казанлък",
        category: "Фестивали и културни събития",
        url: "https://nadigravanekz.com/"
    },
    {
        title: "Международен фестивал на Лавандулата, Чирпан",
        category: "Фестивали и културни събития",
        url: "https://lavenderforum.bg/"
    },
    {
        title: "Open Buzludzha festival, вр. Бузлуджа",
        category: "Фестивали и културни събития",
        url: "https://buzludzha-project.com/en/festival/"
    },
    {
        title: "Midalidare Rock Festival, с. Могилово",
        category: "Фестивали и културни събития",
        url: "https://midalidarerock.bg/"
    },
    {
        title: "We are going to Мъглиж, Мъглиж",
        category: "Фестивали и културни събития",
        url: "https://www.goingtomaglizh.com/"
    },
    {
        title: "Национален тракийски фолклорен събор „Богородична стъпка”, Старозагорски бани",
        category: "Фестивали и културни събития",
        url: "https://www.facebook.com/BogorodichnaStapka/"
    },

]

const touristAttractionsLinks = [
    {
        title: "Археологически парк - Раднево",
        category: "Туристически атракции",
        url: "https://www.facebook.com/ArheologiceskiParkRadnevo/?locale=bg_BG"
    },
    {
        title: "Етнографски комплекс „Дамасцена“, Скобелево",
        category: "Туристически атракции",
        url: "https://www.damascena.net/"
    },
    {
        title: "Розоварна „Еньо Бончев“, с. Търничени",
        category: "Туристически атракции",
        url: "https://www.eniobonchev.com/"
    },
    {
        title: "Музей на виното Мидаледаре, с. Могилово",
        category: "Туристически атракции",
        url: "https://midalidare.bg/page/109/%D0%9F%D1%8A%D1%82%D1%8F%D1%82-%D0%BD%D0%B0-%D0%B2%D0%B8%D0%BD%D0%BE%D1%82%D0%BE.html"
    },
    {
        title: "Музей на бирата „Светът на Загорка“, Стара Загора",
        category: "Туристически атракции",
        url: "https://zagorkacompany.bg/pivovarna/svetat-na-zagorka"
    },
    {
        title: "Музей на магарето, гр. Гурково",
        category: "Туристически атракции",
        url: "https://gurkovo.bg/page/78/%D0%9C%D1%83%D0%B7%D0%B5%D0%B9.html"
    },
    {
        title: "Музей на мотиката, с. Гранит",
        category: "Туристически атракции",
        url: "https://www.facebook.com/people/%D0%9F%D1%8A%D1%80%D0%B2%D0%B8-%D0%BC%D1%83%D0%B7%D0%B5%D0%B9-%D0%BD%D0%B0-%D0%BC%D0%BE%D1%82%D0%B8%D0%BA%D0%B0%D1%82%D0%B0/100057217580770/"
    },
    {
        title: "Етнографска сбирка „Старите българи“, Павел баня",
        category: "Туристически атракции",
        url: "https://www.facebook.com/p/%D0%95%D1%82%D0%BD%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D1%81%D0%BA%D0%B0-%D1%81%D0%B1%D0%B8%D1%80%D0%BA%D0%B0-%D0%A1%D1%82%D0%B0%D1%80%D0%B8%D1%82%D0%B5-%D0%B1%D1%8A%D0%BB%D0%B3%D0%B0%D1%80%D0%B8-%D0%9F%D0%B0%D0%B2%D0%B5%D0%BB-%D0%B1%D0%B0%D0%BD%D1%8F-100057608492873/"
    },
]

const tourismLinks = [
    {
        title: "Тракийски туристически район",
        category: "Туризъм",
        url: "https://trakiatour.com/"
    },
    {
        title: "Туристически район „Долината на розите“",
        category: "Туризъм",
        url: "https://outr.bulgariatravel.org/bg/rose-valley"
    },
    {
        title: "Открий Казанлък",
        category: "Туризъм",
        url: "https://visitkazanlak.bg/"
    },
    {
        title: "Стара Загора - за теб",
        category: "Туризъм",
        url: "https://visitstarazagora.bg/"
    },
    {
        title: "Stara Zagora Love",
        category: "Туризъм",
        url: "https://starazagora.love/bg/"
    },
    {
        title: "Посети Шипка",
        category: "Туризъм",
        url: "https://shipka.info/"
    },
    {
        title: "Исторически интерактивен център, Павел баня",
        category: "Туризъм",
        url: "https://www.facebook.com/BulgarianNewsAgency/videos/%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%B2%D0%B5%D0%BD-%D0%B8%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8-%D1%86%D0%B5%D0%BD%D1%82%D1%8A%D1%80-%D0%BF%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B0%D0%B2%D1%8F-%D0%B8%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F%D1%82%D0%B0-%D0%BD%D0%B0-%D0%BF%D0%B0%D0%B2%D0%B5%D0%BB-%D0%B1%D0%B0%D0%BD%D1%8F/870302624303598/"
    },
]

export default [museumLinks, galleryLinks, festivalLinks, touristAttractionsLinks, tourismLinks];