const papers = [
    {
        name: "CONCEPTUAL MODEL FOR INTEGRATED DIGITAL PRESENTATION OF REGIONAL CULTURAL HERITAGE (BASED ON THE EXAMPLE OF STARA ZAGORA DISTRICT)",
        authors: ["Стела Стефанова"],
        status: "Публикувана",
        year: 2024,
        magazine: "Cultural and Historical Heritage: Preservation, Presentation, Digitalization (KIN Journal)",
        tom: 10,
        count: 2,
        pages: "51-75",
        url: "https://doi.org/10.55630/KINJ.2024.100204"
    },
    {
        name: "НЕДВИЖИМИ КУЛТУРНИ ЦЕННОСТИ С НАЦИОНАЛНО ЗНАЧЕНИЕ В ОБЛАСТ СТАРА ЗАГОРА – ПРЕДСТАВЯНЕ И ПОПУЛЯРИЗИРАНЕ В ОНЛАЙН ПРОСТРАНСТВОТО",
        authors: ["Стела Стефанова"],
        status: "Публикувана",
        year: 2023,
        magazine: "Cultural and Historical Heritage: Preservation, Presentation, Digitalization (KIN Journal)",
        tom: 9,
        count: 1,
        pages: "62-68",
        url: "https://doi.org/10.55630/KINJ.2023.090105"
    },
    {
        name: "КУЛТУРНОТО НАСЛЕДСТВО НА ОБЛАСТ СТАРА ЗАГОРА В ДИГИТАЛНИ ТУРИСТИЧЕСКИ МАРШРУТИ",
        authors: ["Stela Stefanova"],
        status: "Публикувана",
        year: 2024,
        collection: "География, регионално развитие и туризъм – съвременни измерения",
        editor: "С. Станкова, Д. Кабакчиева",
        publisher: "УИ „Епископ Константин Преславски“",
        pages: "371-384",
        url: "https://www.shu.bg/wp-content/uploads/file-manager-advanced/users/faculties/fpn/izdaniya/geo-nauki/sborni-konferenciya-grrt-2024.pdf"
    },
    {
        name: "SOCIALIZATION OF IMMOVABLE SITES OF THE CULTURAL HERITAGE OF KAZANLAK MUNICIPALITY",
        authors: ["Stela Stefanova"],
        status: "Публикувана",
        year: 2023,
        collection: "Tourism for Sustainable Future",
        editor: "Desislava Varadzhakova",
        publisher: "Avangard Prima, Sofia",
        pages: "652-665",
        url: "https://drive.google.com/file/d/14m8H4tT4pNURNgZ5WoyeEMbY9kVopMrr/view"
    }
]

export default papers;