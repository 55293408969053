const news = [
    {
        id: 1,
        title: "Име на новина",
        summary: "Кратко описание на новината",
        paragraphs: [],
    
    },
]

export default news;