import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './PhotoAlbum.css';

const PhotoAlbums = () => {
	const [albums, setAlbums] = useState([]);
	const { type, albumsCategoryId } = useParams();

	const themeStyle = (src) => ({
		backgroundImage: 'url(' + src + ')',
		backgroundPosition: 'center'
	})

	useEffect(() => {
		const importAlbums = async () => {			
			const importedSights = await import(`../../data/sights/sights_all.js`);
			const importedAlbums = importedSights.default.filter(sight => sight.filter == type && sight.subFilter == albumsCategoryId && sight.photosCount > 3)
			setAlbums(importedAlbums);
		}
		importAlbums();
	}, [albumsCategoryId, type]);
	
	return (
		<div className="Album">
			<div className="Album-list">
				{albums.map(album => (
					<li className="Album-item">
						<div className="Album-item-content">
							<Link to={`/albums/${album.id}`} className="Album-item-content" style={themeStyle(`/photo_albums/id_${album.id}/${album.img}`)}/>
							<h2 className="Album-item-text">{album.name}</h2>
						</div>
					</li>
				))}
			</div>
		</div>
	)
}

export default PhotoAlbums;