import { useParams } from "react-router-dom";
import Slideshow from '../../components/Slideshow/Slideshow';
import "./PhotoAlbum.css"
import { useEffect, useState } from "react";

const PhotoAlbum = () => {
	const { albumId } = useParams();
	const [ albumData, setAlbumData ] = useState({});

	useEffect(() => {
		const importAlbums = async () => {			
			const allSights = await import(`../../data/sights/sights_all.js`);
			const sight = allSights.default.find(sight => sight.id == albumId)
			
			setAlbumData(sight);
		}
		importAlbums();
	}, [albumId]);

	return (
		<div className="PhotoAlbumPage">
			{albumData ?
				<><h2 className="Album-header">{albumData.name}</h2>
				<Slideshow albumData={albumData}/></>
				: <h2>Няма албум за тази категория</h2>
			}

		</div>
	)
}

export default PhotoAlbum;